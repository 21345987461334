import { useEffect } from 'react';
import { sendAnalytics } from '../../utils/analyticsUtils';

const globalAnalyticsListener = (prefix = 'COMPONENT_EVENT') => {
  useEffect(() => {
    let focusedElement = null;

    const eventListener = e => {
      const { 'data-analytics-name': customName } = e.target.dataset;
      const labelName = e.target.label || e.target.name;
      const ariaLabel = e.target.ariaLabel;
      if (!(customName || labelName || ariaLabel)) return;

      let details = '';

      switch (e.type) {
        case 'click':
          if (e.target.tagName === 'BUTTON') {
            details = `Button ${customName || labelName || ariaLabel} clicked`;
          }
          break;
        case 'focusin':
          focusedElement = e.target;
          break;
        case 'focusout':
          if (focusedElement === e.target && e.target.tagName === 'INPUT') {
            details = `Input field ${
              customName || labelName || ariaLabel
            } changed with value ${e.target.value}`;
          }
          break;
        default:
          return;
      }

      if (details) {
        sendAnalytics({
          defaultName: `${prefix}_${customName || labelName || ariaLabel}`,
          defaultDetails: details
        });
      }
    };

    document.addEventListener('click', eventListener);
    document.addEventListener('focusin', eventListener);
    document.addEventListener('focusout', eventListener);

    return () => {
      document.removeEventListener('click', eventListener);
      document.removeEventListener('focusin', eventListener);
      document.removeEventListener('focusout', eventListener);
    };
  }, []);

  return null;
};

export default globalAnalyticsListener;
